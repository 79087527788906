import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import OrganizationList from './OrganizationList';
import OrganizationCreate from './OrganizationCreate';
function Organization() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace('/*', '');
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: OrganizationList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: OrganizationCreate })));
}
export default Organization;
