import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UsersList from './UsersList';
import UserCreate from './UserCreate';
function Users() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace('/*', '');
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: UsersList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: UserCreate })));
}
export default Users;
