var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Row, Col } from '@folio/stripes/components';
import { useHistory } from 'react-router-dom';
var UserDetail = function (_a) {
    var user = _a.user, setSelectedUser = _a.setSelectedUser;
    var history = useHistory();
    var _b = useState({
        general: true,
        address: true,
        contacts: true,
        comments: true,
        timestamps: true,
    }), accordionStatus = _b[0], setAccordionStatus = _b[1];
    var _c = useState(true), expandAll = _c[0], setExpandAll = _c[1];
    var formatDate = function (dateString) {
        if (!dateString)
            return 'N/A'; // Return 'N/A' if the date is undefined or null
        var date = new Date(dateString);
        var day = date.getDate().toString().padStart(2, '0');
        var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        var year = date.getFullYear();
        var hours = date.getHours().toString().padStart(2, '0');
        var minutes = date.getMinutes().toString().padStart(2, '0');
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var renderField = function (label, value) { return (React.createElement(Row, { style: { marginBottom: '25px' } },
        React.createElement(Col, { xs: 12 },
            React.createElement("div", { style: { marginBottom: '5px' } },
                React.createElement("strong", { style: { fontSize: '1.1em', paddingRight: '5px' } }, label)),
            React.createElement("div", null, value || 'N/A')))); };
    // const hasMailingAddress = organisation.mailingAddress?.street || organisation.mailingAddress?.postalCode || organisation.mailingAddress?.municipality;
    // const hasContacts = organisation.contacts && organisation.contacts.length > 0;
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[section] = !prevState[section], _a)));
        });
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({
                general: false,
                address: false,
                contacts: false,
                comments: false,
                timestamps: false,
            });
        }
        else {
            setAccordionStatus({
                general: true,
                address: true,
                contacts: true,
                comments: true,
                timestamps: true,
            });
        }
        setExpandAll(!expandAll);
    };
    return (React.createElement(React.Fragment, null));
};
export default UserDetail;
