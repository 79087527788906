var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, TextField, Pane, MenuSection, Datepicker, Accordion, Checkbox, IconButton, AccordionSet, Icon } from '@folio/stripes/components';
import TableComponent from '../../components/TableComponent';
import NavComponent from '../../components/NavComponent';
import PublisherDetail from './PublisherDetail';
import { StripesConnectedSource } from '@folio/stripes/smart-components';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var PublisherList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var _a = useState(''), nameFilter = _a[0], setNameFilter = _a[1];
    var _b = useState(''), icoFilter = _b[0], setIcoFilter = _b[1];
    var _c = useState(undefined), startDateFilter = _c[0], setStartDateFilter = _c[1];
    var _d = useState(undefined), endDateFilter = _d[0], setEndDateFilter = _d[1];
    var _e = useState(null), selectedPublisher = _e[0], setSelectedPublisher = _e[1];
    var _f = useState({
        name: '',
        companyNumber: '',
        startDate: undefined,
        endDate: undefined,
        status: [],
    }), filters = _f[0], setFilters = _f[1];
    var statusTranslations = {
        REQUEST: 'Žádost',
        REJECTED: 'Odmítnuto',
        ACTIVE: 'Aktivní',
        CLOSING: 'V likvidaci',
        CLOSED: 'Ukočený',
    };
    var _g = useState(true), filterPaneIsVisible = _g[0], setFilterPaneIsVisible = _g[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var publisherId = queryParams.get('detail');
        setFilters({
            name: queryParams.get('name') || '',
            companyNumber: queryParams.get('companyNumber') || '',
            startDate: queryParams.get('startDate') || undefined,
            endDate: queryParams.get('endDate') || undefined,
            status: queryParams.getAll('status'),
        });
        setNameFilter(queryParams.get('name') || '');
        setIcoFilter(queryParams.get('companyNumber') || '');
        setStartDateFilter(queryParams.get('startDate') || undefined);
        setEndDateFilter(queryParams.get('endDate') || undefined);
        if (publisherId) {
            fetchPublishers({ name: '', companyNumber: '', status: [] }).then(function (publishers) {
                var publisher = publishers.find(function (p) { return p.id === publisherId; });
                if (publisher) {
                    setSelectedPublisher(publisher);
                    // Ensure filters are preserved when opening detail
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.name)
            params.set('name', filters.name);
        if (filters.companyNumber)
            params.set('companyNumber', filters.companyNumber);
        if (filters.startDate)
            params.set('startDate', filters.startDate); // Přidání filtru pro startDate
        if (filters.endDate)
            params.set('endDate', filters.endDate); // Přidání filtru pro endDate
        filters.status.forEach(function (status) { return params.append('status', status); });
        history.push({ search: params.toString() });
    };
    var handleStatusChange = function (status) {
        setFilters(function (prevFilters) {
            var newStatus = prevFilters.status.includes(status)
                ? prevFilters.status.filter(function (s) { return s !== status; })
                : __spreadArray(__spreadArray([], prevFilters.status, true), [status], false);
            var updatedFilters = __assign(__assign({}, prevFilters), { status: newStatus });
            updateUrlWithFilters(updatedFilters);
            return updatedFilters;
        });
    };
    var handleClearFilters = function () {
        setNameFilter('');
        setIcoFilter('');
        setStartDateFilter(undefined);
        setEndDateFilter(undefined);
        setFilters({
            name: '',
            companyNumber: '',
            startDate: undefined,
            endDate: undefined,
            status: [],
        });
        history.push({ search: '' });
    };
    var fetchPublishers = function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (filters) {
            var name_1, companyNumber, startDate, endDate, status_1, queryParts, statusQuery, queryParam, response, data, error_1;
            if (filters === void 0) { filters = {
                name: '',
                companyNumber: '',
                status: []
            }; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        name_1 = filters.name, companyNumber = filters.companyNumber, startDate = filters.startDate, endDate = filters.endDate, status_1 = filters.status;
                        queryParts = [];
                        if (name_1)
                            queryParts.push("name=\"".concat(name_1, "\""));
                        if (companyNumber)
                            queryParts.push("companyNumber=\"".concat(companyNumber, "\""));
                        // Here we remove the milliseconds from the startDate and endDate
                        if (startDate)
                            queryParts.push("birthDate>=\"".concat(new Date(startDate).toISOString().split('.')[0], "\""));
                        if (endDate)
                            queryParts.push("birthDate<=\"".concat(new Date(endDate).toISOString().split('.')[0], "\""));
                        if (status_1.length > 0) {
                            statusQuery = status_1.map(function (s) { return "status==\"".concat(s, "\""); }).join(' or ');
                            queryParts.push("".concat(statusQuery));
                        }
                        queryParam = queryParts.length > 0 ? "query=".concat(encodeURIComponent(queryParts.join(' and '))) : '';
                        return [4 /*yield*/, ky.get("publishers?".concat(queryParam), {
                                headers: {
                                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                                    Pragma: 'no-cache',
                                    Expires: '0',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data.publishers];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Fetch error:', error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var isAnyFilterActive = function () {
        return (!!filters.name ||
            !!filters.companyNumber ||
            !!filters.startDate ||
            !!filters.endDate ||
            filters.status.length > 0);
    };
    var _h = useQuery(['publishers', filters], function () { return fetchPublishers(filters); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _j = _h.data, publishersData = _j === void 0 ? [] : _j, isLoading = _h.isLoading, isError = _h.isError;
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: 'Nakladatel',
            key: 'name',
            clickable: true,
            onClick: function (item) {
                // Append filters to the URL when navigating to detail
                var currentParams = new URLSearchParams(location.search);
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisher(item);
            },
        },
        {
            name: 'Doplňek k názvu',
            key: 'nameSuffix',
        },
        {
            name: 'Kvalifikátor',
            key: 'qualifier',
        },
        {
            name: 'IČO',
            key: 'companyNumber',
        },
        {
            name: 'Datum narození',
            key: 'birthData',
        },
        {
            name: 'Status',
            key: 'status',
            render: function (status) { return statusTranslations[status] || status; },
        },
    ];
    var closePublisher = useMutation(function (publisherId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ky.post("publishers/".concat(publisherId, "/closing"), {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to close publisher');
                    }
                    return [2 /*return*/, response.json()];
            }
        });
    }); }, {
        onSuccess: function () {
            queryClient.invalidateQueries(['publishers', filters]);
            setSelectedPublisher(null);
            history.replace({ search: new URLSearchParams(location.search).toString() }); // Preserve other filters
        },
        onError: function (error) {
            console.error('Failed to close publisher:', error);
        },
    }).mutateAsync;
    var confirmPublisher = useMutation(function (publisherId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ky.post("publishers/".concat(publisherId, "/confirmData"), {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to confirm publisher');
                    }
                    return [2 /*return*/, response.json()];
            }
        });
    }); }, {
        onSuccess: function () {
            queryClient.invalidateQueries(['publishers', filters]);
            setSelectedPublisher(null);
            history.replace({ search: new URLSearchParams(location.search).toString() }); // Preserve other filters
        },
        onError: function (error) {
            console.error('Failed to confirm publisher:', error);
        },
    }).mutateAsync;
    var handleCloseClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedPublisher) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, closePublisher(selectedPublisher.id)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error closing publisher:', error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleConfirmClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedPublisher) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, confirmPublisher(selectedPublisher.id)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error('Error confirming publisher:', error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return publishersData; };
    source.resultCount = function () { return publishersData.length; };
    source.totalCount = function () { return publishersData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return isError ? { message: 'Error loading data' } : null; };
    source.failureMessage = function () { return 'Error loading data'; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                        React.createElement(Icon, { icon: "times-circle-solid" }, "Resetovat v\u0161echno")),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "N\u00E1zev a I\u010CO", separator: false },
                            React.createElement(TextField, { placeholder: "N\u00E1zev...", value: nameFilter, onChange: function (e) { return setNameFilter(e.target.value); } }),
                            React.createElement(TextField, { placeholder: "I\u010CO...", value: icoFilter, onChange: function (e) { return setIcoFilter(e.target.value); } }),
                            React.createElement(Button, { onClick: function () {
                                    setFilters(__assign(__assign({}, filters), { name: nameFilter, companyNumber: icoFilter }));
                                    updateUrlWithFilters(__assign(__assign({}, filters), { name: nameFilter, companyNumber: icoFilter }));
                                }, marginBottom0: true }, "Aplikovat n\u00E1zev a I\u010CO")),
                        React.createElement(Accordion, { label: "Datum narozen\u00ED", separator: false },
                            React.createElement("div", null,
                                React.createElement("label", { htmlFor: "start-date" }, "Od:"),
                                React.createElement(Datepicker, { id: "start-date", value: startDateFilter || '', onChange: function (e, formatted, dateString) { return setStartDateFilter(dateString || undefined); }, fullWidth: true, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY" })),
                            React.createElement("div", null,
                                React.createElement("label", { htmlFor: "end-date" }, "Do:"),
                                React.createElement(Datepicker, { id: "end-date", value: endDateFilter || '', onChange: function (e, formatted, dateString) { return setEndDateFilter(dateString || undefined); }, fullWidth: true, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY" })),
                            React.createElement(Button, { onClick: function () {
                                    if (startDateFilter && endDateFilter) {
                                        setFilters(__assign(__assign({}, filters), { startDate: startDateFilter, endDate: endDateFilter }));
                                        updateUrlWithFilters(__assign(__assign({}, filters), { startDate: startDateFilter, endDate: endDateFilter }));
                                    }
                                    else {
                                        alert('Vyberte od a do dátumy');
                                    }
                                }, marginBottom0: true }, "Aplikovat datum")),
                        React.createElement(Accordion, { label: "Status", id: "status-filter", separator: false }, ['ACTIVE', 'REQUEST', 'CLOSING', 'CLOSED', 'REJECTED'].map(function (status) { return (React.createElement(Checkbox, { key: status, label: statusTranslations[status], checked: filters.status.includes(status), onChange: function () { return handleStatusChange(status); } })); })))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: React.createElement(React.Fragment, null,
                    React.createElement("span", null, "Seznam nakladatel\u016F")), paneSub: publishersData.length > 0 ? "Nalezeno ".concat(publishersData.length, " z\u00E1znam\u016F") : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push('/brpublishers/publishers/create');
                            } }, "Nov\u00FD")));
                }, firstMenu: !filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement(TableComponent, { data: publishersData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, searchTerm: nameFilter, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedPublisher ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                    setSelectedPublisher(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete('detail');
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: handleConfirmClick, fullWidth: true }, "Potvrdit platnost \u00FAdaj\u016F"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brpublishers/publishers/edit/".concat(selectedPublisher.id)); }, fullWidth: true }, "Zm\u011Bnit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: handleCloseClick, fullWidth: true }, "V likvidaci"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brpublishers/publishers/close/".concat(selectedPublisher.id)); }, fullWidth: true }, "Ukon\u010Dit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { }, fullWidth: true }, "Obnovit")));
                } },
                React.createElement(PublisherDetail, { publisher: selectedPublisher, setSelectedPublisher: setSelectedPublisher }))) : null)));
};
export default PublisherList;
