var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, Pane, AccordionSet, Accordion, TextField, IconButton, Row, Col, PaneFooter, MenuSection } from '@folio/stripes/components';
import NavComponent from '../../components/NavComponent';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var MyProfileDetail = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState(true), filterPaneIsVisible = _a[0], setFilterPaneIsVisible = _a[1];
    var _b = useState(true), expandAll = _b[0], setExpandAll = _b[1];
    var _c = useState({
        organization: true,
        profile: true,
    }), accordionStatus = _c[0], setAccordionStatus = _c[1];
    var fetchUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, ky.get('user', {
                            headers: {
                                'Cache-Control': 'no-cache, no-store, must-revalidate',
                                Pragma: 'no-cache',
                                Expires: '0',
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data];
                case 3:
                    error_1 = _a.sent();
                    console.error('Fetch error:', error_1);
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _d = useQuery(['user'], fetchUser, {
        staleTime: 0,
        cacheTime: 0,
    }), user = _d.data, isLoading = _d.isLoading, isError = _d.isError;
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var expandAllSections = function () {
        setAccordionStatus({
            organization: true,
            profile: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            organization: false,
            profile: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: "M\u016Fj profil", firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } })) : (React.createElement(React.Fragment, null)), actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/my-profile/select-organization");
                            } }, "P\u0159ihl\u00E1sit se do jin\u00E9 Organizace"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/my-profile/password");
                            } }, "Zm\u011Bnit Heslo")));
                }, footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", marginBottom0: true }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "danger", marginBottom0: true }, "Odhl\u00E1sit se") }) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                        React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { organization: !accordionStatus.organization })); } },
                        React.createElement(TextField, { label: "Organizace", value: "Albatros", disabled: true })),
                    React.createElement(Accordion, { label: "M\u016Fj u\u017Eivatel", open: accordionStatus.profile, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { profile: !accordionStatus.profile })); } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Jm\u00E9no", value: (user === null || user === void 0 ? void 0 : user.firstName) || '', required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "P\u0159\u00EDjmen\u00ED", value: (user === null || user === void 0 ? void 0 : user.lastName) || '', required: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Email", value: (user === null || user === void 0 ? void 0 : user.email) || '', required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Role", value: "Spr\u00E1vce nakladatele, Garant nakladatele", disabled: true })))))))));
};
export default MyProfileDetail;
