var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, TextField, Pane, MenuSection, Accordion, Checkbox, IconButton, AccordionSet, Icon } from '@folio/stripes/components';
import TableComponent from '../../components/TableComponent';
import NavComponent from '../../components/NavComponent';
import UserDetail from './UserDetail';
import { StripesConnectedSource } from '@folio/stripes/smart-components';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var UsersList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var _a = useState(''), nameFilter = _a[0], setNameFilter = _a[1];
    var _b = useState(''), organizationFilter = _b[0], setOrganizationFilter = _b[1];
    var _c = useState(false), archivedFilter = _c[0], setArchivedFilter = _c[1];
    var _d = useState(null), selectedUser = _d[0], setSelectedUser = _d[1];
    var _e = useState({
        name: '',
        organization: '',
        archived: false,
    }), filters = _e[0], setFilters = _e[1];
    var _f = useState(true), filterPaneIsVisible = _f[0], setFilterPaneIsVisible = _f[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var userId = queryParams.get('detail');
        setFilters({
            name: queryParams.get('name') || '',
            organization: queryParams.get('organization') || '',
            archived: queryParams.get('archived') === 'true', // Parse archived as a boolean
        });
        setNameFilter(queryParams.get('name') || '');
        setOrganizationFilter(queryParams.get('organization') || '');
        setArchivedFilter(queryParams.get('archived') === 'true');
        if (userId) {
            fetchUsers({ name: '', organization: '', archived: false }).then(function (users) {
                var user = users.find(function (p) { return p.id === userId; });
                if (user) {
                    setSelectedUser(user);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.name)
            params.set('name', filters.name);
        if (filters.organization)
            params.set('organization', filters.organization);
        if (filters.archived !== undefined)
            params.set('archived', filters.archived.toString());
        history.push({ search: params.toString() });
    };
    var handleClearFilters = function () {
        setNameFilter('');
        setArchivedFilter(false);
        setFilters({
            name: '',
            organization: '',
            archived: false,
        });
        history.push({ search: '' });
    };
    var fetchUsers = function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (filters) {
            var name_1, organization, archived, queryParts, queryParam, response, data, error_1;
            if (filters === void 0) { filters = {
                name: '',
                organization: '',
                archived: false,
            }; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        name_1 = filters.name, organization = filters.organization, archived = filters.archived;
                        queryParts = [];
                        if (name_1)
                            queryParts.push("name=\"".concat(name_1, "\""));
                        if (organization)
                            queryParts.push("organization=\"".concat(organization, "\""));
                        if (archived)
                            queryParts.push("archived=true");
                        else
                            queryParts.push("archived=false");
                        queryParam = queryParts.length > 0 ? "query=".concat(encodeURIComponent(queryParts.join(' and '))) : '';
                        return [4 /*yield*/, ky.get("organizations?".concat(queryParam), {
                                headers: {
                                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                                    Pragma: 'no-cache',
                                    Expires: '0',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data.users];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Fetch error:', error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var isAnyFilterActive = function () {
        return (!!filters.name ||
            filters.archived);
    };
    var _g = useQuery(['users', filters], function () { return fetchUsers(filters); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _h = _g.data, usersData = _h === void 0 ? [] : _h, isLoading = _g.isLoading, isError = _g.isError;
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: 'Příjmení',
            key: 'lastName',
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedUser(item);
            },
        },
        {
            name: 'Jméno',
            key: 'firstName',
        },
        {
            name: 'E-mail',
            key: 'email',
        },
        {
            name: 'Organizace',
            key: 'organization',
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return usersData; };
    source.resultCount = function () { return usersData.length; };
    source.totalCount = function () { return usersData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return isError ? { message: 'Error loading data' } : null; };
    source.failureMessage = function () { return 'Error loading data'; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                        React.createElement(Icon, { icon: "times-circle-solid" }, "Resetovat v\u0161echno")),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Hled\u00E1n\u00ED", separator: false },
                            React.createElement(TextField, { placeholder: "Hledat...", value: nameFilter, onChange: function (e) { return setNameFilter(e.target.value); } }),
                            React.createElement(Button, { onClick: function () {
                                    setFilters(__assign(__assign({}, filters), { name: nameFilter }));
                                    updateUrlWithFilters(__assign(__assign({}, filters), { name: nameFilter, organization: organizationFilter, archived: archivedFilter }));
                                }, marginBottom0: true }, "Aplikovat")),
                        React.createElement(Accordion, { label: "Organizace", separator: false },
                            React.createElement(TextField, { placeholder: "Organizace...", value: organizationFilter, onChange: function (e) { return setNameFilter(e.target.value); } }),
                            React.createElement(Button, { onClick: function () {
                                    setFilters(__assign(__assign({}, filters), { organization: organizationFilter }));
                                    updateUrlWithFilters(__assign(__assign({}, filters), { name: nameFilter, organization: organizationFilter, archived: archivedFilter }));
                                }, marginBottom0: true }, "Aplikovat")),
                        React.createElement(Accordion, { label: "Archivov\u00E1no", id: "archived-filter", separator: false },
                            React.createElement(Checkbox, { label: 'Archivov\u00E1no', checked: archivedFilter, onChange: function () {
                                    setArchivedFilter(!archivedFilter);
                                    setFilters(__assign(__assign({}, filters), { archived: !archivedFilter }));
                                    updateUrlWithFilters(__assign(__assign({}, filters), { archived: !archivedFilter }));
                                } })))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: React.createElement(React.Fragment, null,
                    React.createElement("span", null, "Seznam u\u017Eivatel\u00FA")), paneSub: usersData.length > 0 ? "Nalezeno ".concat(usersData.length, " z\u00E1znam\u016F") : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push('/brusers/users/create');
                            } }, "Nov\u00FD")));
                }, firstMenu: !filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement(TableComponent, { data: usersData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, searchTerm: nameFilter, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedUser ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                    setSelectedUser(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete('detail');
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brpublishers/publishers/edit/".concat(selectedUser.id)); }, fullWidth: true }, "Zm\u011Bnit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brpublishers/publishers/close/".concat(selectedUser.id)); }, fullWidth: true }, "Ukon\u010Dit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { }, fullWidth: true }, "Obnovit")));
                } },
                React.createElement(UserDetail, { user: selectedUser, setSelectedUser: setSelectedUser }))) : null)));
};
export default UsersList;
