import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import Settings from './settings';
import MyProfile from './routes/my-profile/MyProfile.tsx';
import Organization from './routes/organization/Organization.tsx';
import Users from './routes/users/Users.tsx';

class BrPublishers extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    showSettings: PropTypes.bool,
    stripes: PropTypes.shape({
      connect: PropTypes.func
    })
  };

  constructor(props) {
    super(props);

    this.connectedMyProfile = props.stripes.connect(MyProfile);
    this.connectedOrganization = props.stripes.connect(Organization);
    this.connectedUsers = props.stripes.connect(Users);
  }

  render() {
    const {
      showSettings,
      match: { path }
    } = this.props;

    if (showSettings) {
      return <Settings {...this.props} />;
    }

    return (
      <Switch>
        <Route
          path={`${path}/my-profile`}
          component={this.connectedMyProfile}
        />
        <Route
          path={`${path}/users`}
          exact
          component={this.connectedUsers}
        />
        <Route
          path={`${path}/users/*`}
          exact
          component={this.connectedUsers}
        />
        <Route
          path={`${path}/organizations`}
          exact
          component={this.connectedOrganization}
        />
        <Route
          path={`${path}/organizations/*`}
          exact
          component={this.connectedOrganization}
        />
        {/* Add a route for nested routes under /publishers */}
        <Route
          path={`${path}/my-profile/*`}
          component={this.connectedMyProfile}
        />
        {/* Default Redirect */}
        <Route
          render={() => <Redirect to={`${path}/my-profile`} />}
        />
      </Switch>
    );
  }
}

export default BrPublishers;
