var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Pane, TextField, Select, Button, Row, Col, TextArea, Datepicker, MessageBanner, AccordionSet, Accordion, Checkbox, Selection, } from '@folio/stripes/components';
import { useHistory } from 'react-router-dom';
import { useOkapiKy } from '@folio/stripes/core';
import debounce from 'lodash/debounce';
var PublisherCreate = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _a[0], setAccordionStatus = _a[1];
    var _b = useState({
        ico: '',
        name: '',
        nameSuffix: '',
        qualifier: '',
        legalForm: 'LEGAL',
        taxNumber: '',
        birthDate: '',
        mainAddress: {
            street: '',
            postalCode: '',
            municipality: '',
        },
        mailingAddress: {
            street: '',
            postalCode: '',
            municipality: '',
        },
        residentialMunicipality: '',
        residentialRegion: {
            id: '',
            name: ''
        },
        contacts: [],
        alternativeNames: [],
        internalComment: '',
    }), formValues = _b[0], setFormValues = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState({
        show: false,
        message: '',
        type: 'success',
    }), messageBanner = _d[0], setMessageBanner = _d[1];
    var _e = useState(false), showMailingAddress = _e[0], setShowMailingAddress = _e[1];
    var _f = useState(true), expandAll = _f[0], setExpandAll = _f[1];
    var _g = useState([]), regions = _g[0], setRegions = _g[1]; // State for regions
    var _h = useState(''), selectedRegion = _h[0], setSelectedRegion = _h[1];
    var _j = useState(''), addressQuery = _j[0], setAddressQuery = _j[1]; // Holds the current input text for the query
    var _k = useState(''), selectedAddress = _k[0], setSelectedAddress = _k[1]; // Holds the selected addressCode
    var _l = useState([]), addressData = _l[0], setAddressData = _l[1]; // State for address data
    var _m = useState(false), addressLoading = _m[0], setAddressLoading = _m[1]; // Loading state for addresses
    // Debounced function to delay the query call as the user types
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    // Fetch address suggestions from the API based on the query
    var fetchAddress = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true); // Start loading
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 4, 5]);
                    return [4 /*yield*/, ky.get('publisher-address/suggest', {
                            searchParams: {
                                address: query,
                                limit: 10,
                            },
                            headers: {
                                'Cache-Control': 'no-cache, no-store, must-revalidate',
                                Pragma: 'no-cache',
                                Expires: '0',
                            },
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    return [2 /*return*/, data.publisherAddresses];
                case 4:
                    setAddressLoading(false); // Stop loading
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // Handle input changes in the Selection component
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            debouncedSetAddressQuery(inputValue); // Update the query with the typed input using debouncing
            return [2 /*return*/];
        });
    }); };
    // Handle address selection
    var handleAddressSelection = function (selectedValue) {
        setSelectedAddress(selectedValue);
    };
    useEffect(function () {
        if (addressQuery) {
            fetchAddress(addressQuery).then(setAddressData).catch(console.error);
        }
        else {
            setAddressData([]);
        }
    }, [addressQuery]);
    var fetchRegions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, ky.get('regions', {
                            headers: {
                                'Cache-Control': 'no-cache, no-store, must-revalidate',
                                Pragma: 'no-cache',
                                Expires: '0',
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data.regions];
                case 3:
                    error_1 = _a.sent();
                    console.error('Fetch error:', error_1);
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _o = useQuery('regions', fetchRegions, {
        onSuccess: function (data) {
            if (data !== regions) {
                setRegions(data);
            }
        },
    }), _p = _o.data, regionsData = _p === void 0 ? [] : _p, regionsLoading = _o.isLoading;
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddressChange = function (e, type) {
        var _a, _b;
        var _c = e.target, name = _c.name, value = _c.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[type] = __assign(__assign({}, formValues[type]), (_b = {}, _b[name] = value, _b)), _a)));
    };
    var handleContactChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var contacts = __spreadArray([], (formValues.contacts || []), true);
        contacts[index] = __assign(__assign({}, (contacts[index] || {})), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { contacts: contacts }));
    };
    var handleAlternativeNameChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var alternativeNames = __spreadArray([], formValues.alternativeNames, true);
        alternativeNames[index] = __assign(__assign({}, alternativeNames[index]), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: alternativeNames }));
    };
    var addContact = function () {
        setFormValues(__assign(__assign({}, formValues), { contacts: __spreadArray(__spreadArray([], formValues.contacts, true), [{ type: 'EMAIL', value: '' }], false) }));
    };
    var removeContact = function (index) {
        var contacts = __spreadArray([], formValues.contacts, true);
        contacts.splice(index, 1);
        setFormValues(__assign(__assign({}, formValues), { contacts: contacts }));
    };
    var addAlternativeName = function () {
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: __spreadArray(__spreadArray([], formValues.alternativeNames, true), [{ name: '', nonPublic: false }], false) }));
    };
    var removeAlternativeName = function (index) {
        var alternativeNames = __spreadArray([], formValues.alternativeNames, true);
        alternativeNames.splice(index, 1);
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: alternativeNames }));
    };
    var validateForm = function () {
        var newErrors = {};
        if (!formValues.name)
            newErrors.name = 'Název je povinný.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var expandAllSections = function () {
        setAccordionStatus({
            general: true,
            address: true,
            contacts: true,
            alternativeNames: true,
            comments: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            general: false,
            address: false,
            contacts: false,
            alternativeNames: false,
            comments: false,
        });
        setExpandAll(false);
    };
    var handleRegionChange = function (e) {
        var selectedRegionId = e.target.value;
        var selectedRegion = regions.find(function (region) { return region.id === selectedRegionId; });
        setFormValues(__assign(__assign({}, formValues), { residentialRegion: selectedRegion || { id: '', name: '' } }));
        setSelectedRegion(selectedRegionId); // Store selected region ID
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var jsonData, response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    jsonData = {
                        name: formValues.name,
                        nameSuffix: formValues.nameSuffix,
                        qualifier: formValues.qualifier,
                        alternativeNames: formValues.alternativeNames.map(function (altName) { return ({
                            name: altName.name,
                            nameSuffix: altName.nameSuffix,
                            type: altName.type,
                            nonPublic: altName.nonPublic,
                        }); }),
                        legalForm: formValues.legalForm === 'LEGAL' ? 'LEGAL' : 'NATURAL',
                        companyNumber: formValues.ico,
                        taxNumber: formValues.taxNumber,
                        birthDate: formValues.birthDate,
                        mainAddress: {
                            addressCode: '',
                            municipality: formValues.mainAddress.municipality,
                            postalCode: formValues.mainAddress.postalCode,
                            street: formValues.mainAddress.street,
                        },
                        mailingAddress: {
                            addressCode: '',
                            municipality: formValues.mailingAddress.municipality,
                            postalCode: formValues.mailingAddress.postalCode,
                            street: formValues.mailingAddress.street,
                        },
                        residentialMunicipality: formValues.residentialMunicipality,
                        residentialRegion: formValues.residentialRegion, // Use the selected region
                        contacts: formValues.contacts.map(function (contact) { return ({
                            type: contact.type,
                            value: contact.value,
                        }); }),
                        internalComment: formValues.internalComment,
                        origin: 'AGENCY',
                        status: 'ACTIVE',
                        createTime: new Date().toISOString(),
                        dataConfirmationTime: new Date().toISOString(),
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    console.log('Submitting data:', JSON.stringify(jsonData, null, 2));
                    return [4 /*yield*/, ky.post('publishers', {
                            json: jsonData,
                            headers: {
                                'Content-Type': 'application/json',
                                'x-okapi-tenant': 'tritius',
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Error: ".concat(response.statusText));
                    }
                    setMessageBanner({
                        show: true,
                        message: 'Nakladatel byl úspěšně vytvořen!',
                        type: 'success',
                    });
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error creating publisher:', error_2);
                    console.log('Submitting data:', JSON.stringify(jsonData, null, 2));
                    setMessageBanner({
                        show: true,
                        message: 'Došlo k chybě při vytváření nakladatele.',
                        type: 'error',
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var footer = (React.createElement(Row, { style: { padding: '20px' } },
        " ",
        React.createElement(Col, { xs: 6 },
            React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, fullWidth: true }, "Zru\u0161it")),
        React.createElement(Col, { xs: 6 },
            React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit, fullWidth: true }, "Ulo\u017Eit"))));
    return (React.createElement(Pane, { defaultWidth: "50%", paneTitle: "Zalo\u017Een\u00ED nakladatele", footer: footer },
        messageBanner.show && (React.createElement(MessageBanner, { type: messageBanner.type, style: {
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 9999,
                width: '300px',
            } }, messageBanner.message)),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(AccordionSet, null,
                React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general })); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "N\u00E1zev", name: "name", value: formValues.name, onChange: handleInputChange, required: true, error: errors.name })),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "Dodatek", name: "nameSuffix", value: formValues.nameSuffix, onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "I\u010CO", name: "ico", value: formValues.ico, onChange: handleInputChange })),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "\u010C\u00EDslo DI\u010C", name: "taxNumber", value: formValues.taxNumber, onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 6 },
                            React.createElement(Select, { label: "Forma", name: "legalForm", value: formValues.legalForm, onChange: handleInputChange },
                                React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                React.createElement("option", { value: "PHYSICAL" }, "Fyzick\u00E1 osoba"))),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(Datepicker, { label: "Datum narozen\u00ED", value: formValues.birthDate, onChange: function (e, formattedDate, dateString) {
                                    setFormValues(__assign(__assign({}, formValues), { birthDate: dateString || '' }));
                                }, backendDateStandard: "YYYY-MM-DD" // Format for date to backend (ISO format)
                                , dateFormat: "DD/MM/YYYY" // Display date format (European)
                             })))),
                React.createElement(Accordion, { label: "Adresa", open: accordionStatus.address, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address })); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 6 },
                            React.createElement(Selection, { label: "Adresa", name: "publisherAddress", value: selectedAddress, disabled: addressLoading, onChange: handleAddressSelection, dataOptions: __spreadArray([
                                    { value: '', label: addressLoading ? 'Loading addresses...' : '--- Select an address ---' }
                                ], addressData.map(function (address) { return ({
                                    value: address.addressCode,
                                    label: address.address,
                                }); }), true) })),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(TextField, { label: "Adresa", name: "street", value: formValues.mainAddress.street, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, disabled: true, required: true })),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: formValues.mainAddress.postalCode, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); } })),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(Select, { label: "Region", name: "residentialRegion", value: selectedRegion, onChange: handleRegionChange, dataOptions: __spreadArray([
                                    { value: '', label: '--- Select a region ---' }
                                ], regionsData.map(function (region) { return ({
                                    value: region.id,
                                    label: region.name,
                                }); }), true) }))),
                    React.createElement(Checkbox, { label: "Li\u0161\u00ED se koresponden\u010Dn\u00ED adresa?", checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }),
                    showMailingAddress && (React.createElement(React.Fragment, null,
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(TextField, { label: "Koresponden\u010Dn\u00ED adresa", name: "street", value: formValues.mailingAddress.street, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: formValues.mailingAddress.postalCode, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "M\u011Bsto", name: "municipality", value: formValues.mailingAddress.municipality, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })))))),
                React.createElement(Accordion, { label: "Kontakty", open: accordionStatus.contacts, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { contacts: !accordionStatus.contacts })); } },
                    formValues.contacts.map(function (contact, index) { return (React.createElement(Row, { key: index },
                        React.createElement(Col, { xs: 4 },
                            React.createElement(Select, { label: "Typ kontaktu", name: "type", value: contact.type, onChange: function (e) { return handleContactChange(index, e); } },
                                React.createElement("option", { value: "EMAIL" }, "E-mail"),
                                React.createElement("option", { value: "WEB" }, "Web"),
                                React.createElement("option", { value: "PHONE" }, "Telefon"),
                                React.createElement("option", { value: "DATABOX" }, "Datov\u00E1 schr\u00E1nka"))),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "Kontakt", name: "value", value: contact.value, onChange: function (e) { return handleContactChange(index, e); } })),
                        React.createElement(Col, { xs: 2 },
                            React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContact(index); }, style: { marginTop: '25px' } }, "Odstranit")))); }),
                    React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt")),
                React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames })); } },
                    formValues.alternativeNames.map(function (alternativeName, index) { return (React.createElement(Row, { key: index },
                        React.createElement(Col, { xs: 6 },
                            React.createElement(TextField, { label: "Alternativn\u00ED n\u00E1zev", name: "name", value: alternativeName.name, onChange: function (e) { return handleAlternativeNameChange(index, e); } })),
                        React.createElement(Col, { xs: 4 },
                            React.createElement(Select, { label: "Typ", name: "type", value: alternativeName.type || '', onChange: function (e) { return handleAlternativeNameChange(index, e); } },
                                React.createElement("option", { value: "" }, "-"),
                                React.createElement("option", { value: "previous" }, "P\u0159edchoz\u00ED n\u00E1zev"),
                                React.createElement("option", { value: "imprint" }, "Imprint"),
                                React.createElement("option", { value: "abbreviation" }, "Zkratka"),
                                React.createElement("option", { value: "organizationalUnit" }, "Organiza\u010Dn\u00ED jednotka"),
                                React.createElement("option", { value: "foreignLanguage" }, "Cizojazy\u010Dn\u00FD ekvivalent"))),
                        React.createElement(Col, { xs: 2 },
                            React.createElement(Checkbox, { label: "Neve\u0159ejn\u00E9", checked: alternativeName.nonPublic, onChange: function () {
                                    return setFormValues(__assign(__assign({}, formValues), { alternativeNames: formValues.alternativeNames.map(function (altName, i) {
                                            return i === index ? __assign(__assign({}, altName), { nonPublic: !altName.nonPublic }) : altName;
                                        }) }));
                                } })),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeName(index); }, style: { marginTop: '10px' } }, "Odstranit alternativn\u00ED n\u00E1zev")))); }),
                    React.createElement(Button, { onClick: addAlternativeName }, "P\u0159idat alternativn\u00ED n\u00E1zev")),
                React.createElement(Accordion, { label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments })); } },
                    React.createElement(TextArea, { label: "Pozn\u00E1mka", name: "internalComment", value: formValues.internalComment, onChange: handleInputChange }))))));
};
export default PublisherCreate;
