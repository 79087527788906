var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, Pane, TextField, Row, Col, PaneFooter, AccordionSet, Accordion, Checkbox, Select } from '@folio/stripes/components';
var queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onSuccess: function () {
                queryClient.invalidateQueries('users');
            },
        },
    },
});
// Define role options (these should ideally come from your backend or a constant file)
var availableRoles = [
    { code: 'PublisherAdmin', label: 'Správce nakladatele' },
    { code: 'PublisherGarant', label: 'Garant nakladatele' },
    { code: 'AgencyWorker', label: 'Pracovník agentury' },
];
var UserCreate = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState({
        organization: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        sendPasswordEmail: false,
        selectedRoles: new Set(), // Use a Set to manage selected roles
    }), formData = _a[0], setFormData = _a[1];
    var _b = useState(true), expandAll = _b[0], setExpandAll = _b[1];
    var _c = useState({
        organization: true,
        profile: true,
        role: true,
    }), accordionStatus = _c[0], setAccordionStatus = _c[1];
    var mutation = useMutation(function (newUser) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ky.post('users', { json: newUser })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.json()];
            }
        });
    }); });
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, type = _a.type;
        // Type assertion to make sure e.target is an HTMLInputElement for checkboxes
        if (type === 'checkbox') {
            var target = e.target; // Cast to HTMLInputElement
            var checked_1 = target.checked;
            setFormData(function (prev) {
                var updatedRoles = new Set(prev.selectedRoles);
                if (checked_1) {
                    updatedRoles.add(name); // Add role if checked
                }
                else {
                    updatedRoles.delete(name); // Remove role if unchecked
                }
                return __assign(__assign({}, prev), { selectedRoles: updatedRoles });
            });
        }
        else {
            setFormData(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[name] = e.target.value, _a)));
            });
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newUser, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    newUser = {
                        id: '', // You might need to adjust how you handle this in your backend
                        email: formData.email,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        passwordHash: formData.password, // Or handle this appropriately in your backend
                        shouldChangePassword: true, // Set to true if the user should change the password on first login
                        passwordResetToken: '', // Add logic for password reset if needed
                        passwordResetExpireTime: new Date(), // Or handle this appropriately in your backend
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, mutation.mutateAsync(newUser)];
                case 2:
                    _a.sent();
                    history.push('/brusers');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error creating user:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var expandAllSections = function () {
        setAccordionStatus({
            organization: true,
            profile: true,
            role: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            organization: false,
            profile: false,
            role: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Vytvo\u0159it u\u017Eivatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                        React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { organization: !accordionStatus.organization })); } },
                            React.createElement(Select, { label: "Organizace", name: "organization", value: formData.organization, onChange: handleChange, required: true },
                                React.createElement("option", { value: "" }, "Vyberte organizaci"),
                                React.createElement("option", { value: "org1" }, "Organizace 1"),
                                React.createElement("option", { value: "org2" }, "Organizace 2"),
                                React.createElement("option", { value: "org3" }, "Organizace 3"))),
                        React.createElement(Accordion, { label: "U\u017Eivatelsk\u00E9 informace", open: accordionStatus.profile, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { profile: !accordionStatus.profile })); } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Jm\u00E9no", name: "firstName", value: formData.firstName, onChange: handleChange, required: true })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "P\u0159\u00EDjmen\u00ED", name: "lastName", value: formData.lastName, onChange: handleChange, required: true }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Email", name: "email", value: formData.email, onChange: handleChange, required: true }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Heslo", name: "password", value: formData.password, onChange: handleChange, required: true }),
                                    React.createElement("p", null, "Heslo mus\u00ED m\u00EDt alespo\u0148 8 znak\u016F."))),
                            React.createElement(Checkbox, { label: "Odeslat heslo emailem", name: "sendPasswordEmail", checked: formData.sendPasswordEmail, onChange: handleChange })),
                        React.createElement(Accordion, { label: "Role", open: accordionStatus.role, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { role: !accordionStatus.role })); } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 12 }, availableRoles.map(function (role) { return (React.createElement(Checkbox, { key: role.code, label: role.label, name: role.code, checked: formData.selectedRoles.has(role.code), onChange: handleChange })); }))))))))));
};
export default UserCreate;
